@charset "utf-8";

$background-color-body: #ffffff;

// FONTS
// EXAMPLE FONTS HAVE TO BE INCLUDED BEFORE USED HERE:
// -------------------------------------------
$font-path: '../fonts';
$font-family-sans-serif: sans-serif;
$font-family-lato: 'Lato', sans-serif;
$font-family-cinzel-decorative: 'Cinzel Decorative', cursive;

//$font-family-icon: 'forsthaus';
$font-family-icon: 'Glyphter';
//$font-family-icon-menu: 'forsthaus-menu';

// font-family: 'Lato', sans-serif;
// font-family: 'Cinzel Decorative', cursive;
//
//

// FONT WEIGHTS
// -------------------------------------------
$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;
$bolder: 900;


// COLORS
// -------------------------------------------
//
$color-ci: #004b34; // dunkles Grün bei beim Forsthaus
$color-ci-klostermuehle: #6fb4ab;
$shadow-r: 50;
$shadow-g: 50;
$shadow-b: 50;
$alpha-rgb-shadows: .5;

$font-color-body: #3a2e25;
$font-color-links: #333333;
$color-burger-button: $color-ci;

$color-breadcrumb-green: #004b34;
$color-topmenu-bright: #b2b2b2;

$color-archive-details-link: #666566;
$color-topmenu-dark: #666566;
$color-previous-next-links: #666566;
$color-zitat-title: #666566;
$color-bottom-text: #666566;
$color-subtitle-slider: #666566;
$color-subtitle-flexcontent: #666566;
$color-introtext-lageplan: #666566;
$color-headline-month: #666566;
$color-headline-kalenderevent: #666566;

$color-zitat-copytext: #7b7874;
$color-icon-category-teaser: $color-topmenu-dark;
$color-border-back-to-archive: $color-topmenu-dark;


$color-slider-bottomline: #b2b2b2;

$totalblack: #000000;

$color-border-zimmer-txt-line: #b2b2b2;

$color-white: #ffffff;

$button-color-green: #82a50b;

/*
// $color-ci
- Alle Titles,
- Schrift auf den Textboxen über Headermotiv,
- Title in grauer Teaserbox zwischen Headermotiv und Einträgen
- Text unter Icons im Hauptmenu
*/

// BASE FONT
// -------------------------------------------
$font-family-body: $font-family-lato;




$line-height-factor-body: 1.4;

$font-size-body-large: 20px;
$line-height-body-large: $font-size-body-large * $line-height-factor-body;

$font-size-body-medium: 18px;
$line-height-body-medium: $font-size-body-medium * $line-height-factor-body;

$font-size-body-small: 18px;
$line-height-body-small: $font-size-body-small * $line-height-factor-body;

$font-weight-body: $regular;

$font-family-heading-default: $font-family-cinzel-decorative;

$font-size-h1-large: 60px;
$font-size-h1-medium: 21px;
$font-size-h1-small: 21px;

$font-size-h2-large: 60px;
$font-size-h2-medium: 18px;
$font-size-h2-small: 18px;

$font-size-h3-large: 16px;
$font-size-h3-medium: 16px;
$font-size-h3-small: 16px;

$font-color-h1: $color-ci;
$font-color-h2: $color-ci;
$font-color-h3: $color-ci;


//
// -------------------------------------------
$height-header: 136px;
$height-breadcrumb: 31px;
$height-headerimage-small: 269px;
$height-headerimage-small-small: 143px;

$height-row-logo-menu: 140px;
$height-fixed-header: $height-row-logo-menu + $height-breadcrumb;

$height-row-logo-menu-small: 100px;
$height-fixed-header-small: $height-row-logo-menu-small + $height-breadcrumb;

//$height-fixed-header

// PAGINATION
// -------------------------------------------
$color-grey-blog: #666566;
$color-blog-date: $color-grey-blog;
$color-blog-pagination: $color-grey-blog;
$color-border-pagination-item-normal: $color-grey-blog;
$color-border-pagination-item-inactive: #aaaaaa;
$margin-pagination-item: 4px;

// CATEGORY TEASERBOX
// -------------------------------------------
$width-icon-category-teaser: 85px;
$height-icon-category-teaser: 85px;

// MENU
// -------------------------------------------
$margin-top-menu-main: 52px;
$font-size-menu-main: 20px;
$color-menu-main-normal: $color-ci;
$color-menu-main-hover: $color-ci;

$margin-top-logo-top: 10px;


$width-outterspace-default-small: 14px;

