@charset "utf-8";


// parent element must have a value for 'position':
.base-centered-icon {

	color: transparent;
	position: absolute;
	top: 50%;
	bottom: 50%;
	z-index: 2;
	overflow: hidden;
	margin: auto;
	left: 0;
	right: 0;
}
/* usage example:
.other-class {
	@extend .base-centered-icon;
	background: transparent url('background-image.jpg') no-repeat;
}
*/

.shadow-default {
	// box-shadow: [inset] [horizontal offset] [vertical offset] [blur radius] [spread distance] [color];
	//box-shadow: 1px 1px 5px 0 rgba(50, 50, 50, 0.5), -1px 1px 5px 0 rgba(50, 50, 50, 0.5);
	box-shadow: 0 2px 4px 0 rgba(50, 50, 50, .4);
}

.bg-ci-color {

	background: $color-ci;
}

%base-button {

	@extend .shadow-default;
	@include font-size(16px);

	display: inline-block;
	padding: 2px 20px;
	background: $button-color-green url('../images/patterns/bg-button-teaserbox.png') repeat-x;
	color: $white;
	font-weight: $bold;
	font-style: italic;
}
