@charset "utf-8";

/**
 * Apple specific media queries only
 * http://stephen.io/mediaqueries/
 */


/* iPad Media Queries (All generations - including iPad mini) */
/* (iPads 1-5 and iPad mini) */

// iPad in portrait & landscape:
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)  {

}

// iPad in landscape:
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {

}

// iPad in portrait
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {

}




/* iPad 1 & 2 only Media Queries */

// iPad 1 & 2 in portrait & landscape
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (-webkit-min-device-pixel-ratio: 1){

}

// iPad 1 & 2 in landscape
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1)  {

}

// iPad 1 & 2 in portrait
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait)
and (-webkit-min-device-pixel-ratio: 1) {

}




/* iPad 3 & 4 only Media Queries */

// Retina iPad in portrait & landscape
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (-webkit-min-device-pixel-ratio: 2) {

}

// Retina iPad in landscape
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 2) {

}

// Retina iPad in portrait
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait)
and (-webkit-min-device-pixel-ratio: 2) {

}



////////////////// iPhone 2G-4S

// iPhone 2G-4S in portrait & landscape
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {

}

// iPhone 2G-4S in landscape
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px)
and (orientation : landscape) {

}

// iPhone 2G-4S in portrait
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px)
and (orientation : portrait) {


}



////////////////// iPhone 5 & 5S

// iPhone 5 & 5S in portrait & landscape
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px) {

}

// iPhone 5 & 5S in landscape
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : landscape) {

}

// iPhone 5 & 5S in portrait
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : portrait) {

}


////////////////// iPhone 6

// iPhone 6 in portrait & landscape
@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px) {

}

// iPhone 6 in landscape
@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : landscape) {

}

// iPhone 6 in portrait
@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : portrait) {

	.wrapper-header-textbox {

		width: 347px;
	}

	.burger-button {

		right: 77px;
	}
}



////////////////// iPhone 6 Plus

// iPhone 6 Plus in portrait & landscape
@media only screen
and (min-device-width : 414px)
and (max-device-width : 736px) {

}


// iPhone 6 Plus in landscape
@media only screen
and (min-device-width : 414px)
and (max-device-width : 736px)
and (orientation : landscape) {

}

// iPhone 6 Plus in portrait
@media only screen
and (min-device-width : 414px)
and (max-device-width : 736px)
and (orientation : portrait) {

}


