@charset "utf-8";

.wrapper-blog-teaser,
.single-blog {

	.background-circle {

		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%, -50%);
		width: 85px;
		height: 85px;
		background-color: $color-ci;
		border-radius: 50%;

		.icon-teaser {

			@include font-size(40px);
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			color: $color-white;
		}
	}
}

.single-blog {

	.wrapper-content-blogentry {
		position: relative;
	}

	.head-content-space {

		height: 81px;
	}

	.box-previous-link,
	.box-next-link {

		padding-top: 25px;

		a {

			@include font-size(14px);
			font-weight: bold;
			font-style: italic;
			color: $color-previous-next-links;
		}
	}

	.box-previous-link {

		float: left;
	}
	.box-next-link {

		float: right;
	}

	@include box-back-to-archive($color-border-back-to-archive);
}

.wrapper-blog-teaser {

	@extend .shadow-default;
	position: relative;
	background-color: $color-white;
	padding-top: 40px;
	padding-bottom: 40px;
	margin-bottom: 100px;

	@include breakpoint(medium only) {

		margin-left: 20px;
		margin-right: 20px;
	}

	.box-image {

		padding-left: 40px;
		padding-right: 28px;

		@include breakpoint(small only) {

			padding-left: $width-outterspace-default-small;
			padding-right: $width-outterspace-default-small;
			margin-bottom: 14px;
		}
	}

	.wrapper-date,
	.wrapper-headline {

		padding-left: 40px;
		padding-right: 40px;

		@include breakpoint(small only) {

			padding-left: $width-outterspace-default-small;
			padding-right: $width-outterspace-default-small;
		}
	}

	.wrapper-date {

		padding-top: 20px;

		.date {

			@include font-size(22px);
			font-family: $font-family-lato;
			font-weight: 300;
			color: $color-blog-date;
		}
	}

	.wrapper-headline {

		padding-top: 10px;

		.headline {

			@include font-size(40px);
			line-height: 48px;
			margin-bottom: 30px;

			@include breakpoint(small only) {

				@include font-size(24px);
				line-height: 29px;
				margin-bottom: 24px;
			}

			@include breakpoint(medium only) {

				@include font-size(32px);
				line-height: 38px;
			}
		}
	}

	.wrapper-copytext {

		padding-left: 28px;
		padding-right: 40px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		@include breakpoint(small only) {

			padding-left: $width-outterspace-default-small;
			padding-right: $width-outterspace-default-small;
		}
	}

	.box-copytext {

		p {

			@include font-size(14px);
			line-height: 26px;
			font-weight: 300;
		}
	}

	.box-button {

		position: relative;

		@include breakpoint(small only) {

			margin-top: 40px;
		}

		.button {

			@extend %base-button;
			position: absolute;
			bottom: 0;
			right: 0;

			@include breakpoint(small only) {
				left: 50%;
				right: auto;
				transform: translateX(-50%) ;
			}
		}
	}
}
