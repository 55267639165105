@charset "utf-8";

.wrapper-room-details-dynamic {

	@extend .shadow-default;
	background: $white;
	margin-bottom: 80px;
	font-weight: $light;

	.row-room-data,
	.row-room-description {

		position: relative;
	}

	p {

		@include font-size(14px);
	}

	.wrapper-details {

		padding-top: 14px;
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 40px;

		a {

			@include font-size(14px);
			position: absolute;
			bottom: 12px;
			font-weight: $bold;
			font-style: italic;
			display: inline-block;
			color: $white;
			//padding-left: 14px;
		}

		ul {

			list-style-type: disc;
			margin-left: 17px;
			margin-bottom: 16px;

			li {

				@include font-size(14px);
				line-height: 28px;
				color: $white;
				margin-bottom: 6px;
			}
		}

		p + ul {

			margin-top: -25px;
		}

		.column {

			margin-bottom: 16px;
		}

		.icon {

			font-size: 30px;
			color: $white;
		}

		.title-facilities,
		.title-services {

			@include font-size(14px);
			display: inline-block;
			padding-left: 14px;
			padding-bottom: 10px;
			font-weight: $bold;
			color: $white;
		}

		.title-services {

			padding-top: 10px;
		}

		// ARRANGEMENTS:
		h3 {

			@include font-size(14px);
			margin-bottom: 8px;
			font-family: $font-family-lato;
			font-weight: $bold;
			color: $white;
		}

		p {
			color: $white;
		}
	}

	.column-description {

		.sizer {

			margin: 30px 0;
			padding-top: 14px;
			padding-left: 40px;
			padding-right: 40px;
			border-right: $color-border-zimmer-txt-line 1px solid;
		}

		.title {

			@include font-size(40px);
			margin-bottom: 34px;
			font-family: $font-family-lato;
			font-weight: $light;
		}
	}

	.column-prices {

		.sizer {

			margin: 30px 0;
			padding-top: 14px;
			padding-left: 30px;
			padding-right: 26px;
			padding-bottom: 60px;
		}
	}

	.price-line-box {

		@include font-size(14px);
		margin-bottom: 8px;

		.column {

			&.column-label {

				width: 60%;
			}

			&.column-data {

				width: 40%;
			}

			.price-tbl,
			.price-td {

				margin: 0;
				padding: 0;
				width: 100%;
				height: 100%;
			}

			.price-td {

				vertical-align: bottom;
			}
		}

		.price-label,
		.price {

			display: inline-block;
			line-height: 20px;
		}

		.price-label {

			float: left;
		}

		.price {

			float: right;
		}
	}

	.box-optional-prices {

		margin-top: 20px;
	}

	.button {

		@extend %base-button;
		margin: 0;
		position: absolute;
		bottom: 40px;
		right: 30px;
	}
}
