/* Generated by Glyphter (http://www.glyphter.com) on  Wed Aug 10 2016*/
@font-face {
    font-family: 'Glyphter';
    src: url('../fonts/Glyphter.eot');
    src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Glyphter.woff') format('woff'),
         url('../fonts/Glyphter.ttf') format('truetype'),
         url('../fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}
.icon-bahn:before{content:'\0041';}
.icon-bus:before{content:'\0042';}
.icon-info:before{content:'\0043';}
.icon-kalender:before{content:'\0044';}
.icon-lage:before{content:'\0045';}
.icon-menue:before{content:'\0046';}
.icon-oeffnungszeiten:before{content:'\0047';}
.icon-suche:before{content:'\0048';}
.icon-badewanne:before{content:'\0049';}
.icon-balkon:before{content:'\004a';}
.icon-dusche_bad:before{content:'\004b';}
.icon-dusche:before{content:'\004c';}
.icon-foen:before{content:'\004d';}
.icon-klimaanlage:before{content:'\004e';}
.icon-minibar:before{content:'\004f';}
.icon-nichtraucher:before{content:'\0050';}
.icon-safe:before{content:'\0051';}
.icon-sauna:before{content:'\0052';}
.icon-telefon:before{content:'\0053';}
.icon-terrasse:before{content:'\0054';}
.icon-tv:before{content:'\0055';}
.icon-wellness:before{content:'\0056';}
.icon-wlan:before{content:'\0057';}
.icon-zustellbett:before{content:'\0058';}
.icon-familie:before{content:'\0059';}
.icon-geschichte:before{content:'\005a';}
.icon-golf:before{content:'\0061';}
.icon-pferde:before{content:'\0062';}
.icon-radfahren:before{content:'\0063';}
.icon-romantik:before{content:'\0064';}
.icon-wandern:before{content:'\0065';}
.icon-feiern:before{content:'\0066';}
.icon-freizeit:before{content:'\0067';}
.icon-hotel:before{content:'\0068';}
.icon-restaurant:before{content:'\0069';}
.icon-service:before{content:'\006a';}
.icon-tagen:before{content:'\006b';}
.icon-beratung:before{content:'\006c';}
.icon-blumen:before{content:'\006d';}
.icon-buegelservice:before{content:'\006e';}
.icon-e-ladestation:before{content:'\006f';}
.icon-fahrstuhl:before{content:'\0070';}
.icon-hunde:before{content:'\0071';}
.icon-kartenzahlung:before{content:'\0072';}
.icon-onlinebuchung:before{content:'\0073';}
.icon-parken:before{content:'\0074';}
.icon-picknickservice:before{content:'\0075';}
.icon-rezeption:before{content:'\0076';}
.icon-waescheservice:before{content:'\0077';}
.icon-weckservice:before{content:'\0078';}
.icon-zimmerservice:before{content:'\0079';}
.icon-blog:before{content:'\007a';}
.icon-karriere:before{content:'\0030';}
