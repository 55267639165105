@charset "utf-8";

// http://meyerweb.com/eric/tools/css/reset/
// v2.0 | 20110126
// License: none (public domain)

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

sup {
	vertical-align: super;
	font-size: 10px;
}

/*
YUI 3.17.2 (build 9c3c78e)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/

/**
 * Percents could work for IE, but for backCompat purposes, we are using keywords.
 * x-small is for IE6/7 quirks mode.
 */
body {
	@include font-size($font-size-body-large);
	font-weight: $font-weight-body;
	font-family: $font-family-body;
	line-height: $line-height-body-large;
	*font-size: small; /* for IE */
	*font: x-small; /* for IE in quirks mode */
}

/**
 * Nudge down to get to 13px equivalent for these form elements
 */
select,
input,
button,
textarea {
	font:99% $font-family-body;
}

/**
 * To help tables remember to inherit
 */
table {
	font-size:inherit;
	font:100%;
}

/**
 * Bump up IE to get to 13px equivalent for these fixed-width elements
 */
pre,
code,
kbd,
samp,
tt {
	font-family:monospace;
	*font-size:108%;
	line-height:100%;
}


// END RESET.CSS