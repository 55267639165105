@charset "utf-8";

.page-content-blocks {

	.wrapper-contentblockarea {

		@extend .shadow-default;
		background: $white;
	}

	.wrapper-flexible-content-blocks {

		&.no-introtext-content-top {

			margin-top: 60px;
		}
	}

	.title-copytext-image-2-cols {

		margin-bottom: 46px;

		.headline {

			@include font-size(32px);
			line-height: 44px;
			margin-bottom: 0;
		}

		.subheadline {

			@include font-size(20px);
			font-family: $font-family-lato;
			font-weight: $light;
			color: $color-subtitle-flexcontent;
			margin-bottom: 18px;
		}

		p {

			@include font-size(14px);
			//line-height: 28px;
			color: $totalblack;
			margin-bottom: 13px;
			font-family: $font-family-lato;
			font-weight: $light;
		}

		a {
			@include font-size(14px);
			font-weight: $bold;
			font-style: italic;
			color: $color-archive-details-link;
			/* padding-right: 22px;
			float: right; */
		}

		.wrapper-headlines {

			padding-left: 40px;
			padding-right: 40px;
		}

		.wrapper-noheadlines {

		}

		.sizer-img-right {

			padding-top: 8px;
			padding-left: 28px;
			padding-right: 40px;
		}

		.sizer-img-left {

			padding-top: 8px;
			padding-left: 40px;
			padding-right: 28px;
		}

		.box-image {

			.sizer-img-right,
			.sizer-img-left {

				padding-top: 16px;
			}
		}

		.box-copytext {

		}
	}

	.plaintext-1-2-cols {

		&.row {

			margin-bottom: 46px;
		}

		.box-headline {

			padding-left: 40px;
			padding-right: 40px;
		}

		.headline {

			@include font-size(32px);
			line-height: 44px;
			margin-bottom: 0;
		}

		.subheadline {

			@include font-size(20px);
			font-family: $font-family-lato;
			font-weight: $light;
			color: $color-subtitle-flexcontent;
			margin-bottom: 18px;
		}

		p {

			@include font-size(14px);
			//line-height: 28px;
			color: $totalblack;
			margin-bottom: 13px;
			font-family: $font-family-lato;
			font-weight: $light;
		}

		.sizer-full {

			padding-top: 8px;
			padding-left: 40px;
			padding-right: 40px;
		}

		.sizer-left {

			padding-top: 8px;
			padding-left: 40px;
			padding-right: 40px;
		}

		.sizer-right {

			padding-top: 8px;
			padding-left: 28px;
			padding-right: 40px;
		}


	}
}

