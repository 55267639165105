@charset "utf-8";

@include breakpoint(small only) {
	/* bis max 640px */
	//.small-only { color: $white; }

	.stage {

		background-position: center $height-fixed-header-small;

		&.type-small-stage {

			height: $height-header + $height-breadcrumb + $height-headerimage-small-small;

			.head-image-container {

				height: 179px;
			}
		}

		.head-image-container {

			top: $height-fixed-header-small;
		}
	}

	.mm-opened {

		.burger-button {

			display: block;
		}
	}

	#menu {

		display: none;
	}

	.logo-top {

		width: 132px;
		padding-top: 28px;
		position: absolute;
		left: 50%;
		transform: translate(-50%);
	}
	.logo-top-small {

		display: block;
		width: 230px;
		padding-top: 30px;
		position: absolute;
		left: 50%;
		transform: translate(-50%);
	}
	.wrapper-fixed-header {

		height: $height-fixed-header-small;
	}
	.wrapper-header {

		.row-logo-menu {

			height: $height-row-logo-menu-small;
		}

		.box-langmenu-small {

			position: absolute;
			top: 10px;
			right: 10px;
		}
	}
	.wrapper-breadcrumb {

		box-shadow: 0 2px 4px 0 rgba(50, 50, 50, .4);



		.wrapper-iconmenu-small {

			* {
				outline: 0;
				text-decoration: none;

				// &:focus {

				// 	outline: 0;
				// }
			}

			text-align: center;

			.icon-menue {

			}
			.lnk-menu {

				@include font-size(16px);
				padding-left: 10px;
				padding-right: 8px;
			}

			.icon-telefon {

				@include font-size(16px);
			}
			.lnk-kontakt {

				padding-left: 4px;
				padding-right: 8px;
			}

			.icon-onlinebuchung {

				@include font-size(16px);
			}
			.lnk-onlinebuchung {

				padding-left: 4px;
			}
		}
	}

	.wrapper-menu-main {

		&.mm-menu {
			margin-top: 0;

			li {

				float: left;
			}
		}

		ul li a {

			@include font-size(24px);
			color: $color-ci;
			text-decoration: none;
			display: block;
		}

		ul {

			.nav-main-item,
			.nav-drop-item {

				width: 100%;

				.nav-sub-link {
					@include font-size(16px);
				}
			}

			.nav-main-link {

				@include font-size(16px);
				text-align: left;
			}
		}

		.box-menuicon {

			display: none;
		}
	}

	.home-head-slider {

		top: $height-fixed-header-small;

		.slide-item {

			//background-position: center $height-fixed-header-small;
			background-position: center center;
		}
	}

	.mm-menu {

		background: $white;

		&.mm-offcanvas {

			z-index: 5;
		}
	}

	.wrapper-header-textbox-home {

		padding-bottom: 20px;

		&.box-align-left {

			right: 30%;
			width: 70%;
		}
		&.box-align-right {
			left: 30%;
			width: 70%;
		}

		.overline {

			@include font-size(18px);
			line-height: 24px;
			margin-top: 20px;
			margin-bottom: 4px;
			padding-left: 20px;
			padding-right: 20px;
		}

		.title {

			@include font-size(14px);
			line-height: 22px;
			margin-top: 10px;
			margin-bottom: 20px;
			padding-left: 20px;
			padding-right: 20px;
		}

		.button {

			margin-left: 20px;
		}
	}

	.wrapper-header-textbox {

		width: 292px;
		padding-bottom: 20px;

		.title {

			@include font-size(22px);
			line-height: 26px;
			padding-left: 20px;
			padding-right: 20px;
			margin-top: 16px;
			margin-bottom: 12px;
		}

		p {
			@include font-size(14px);
			line-height: 22px;
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.row-introtext-content-top,
	.row-introtext-content-top-inner {

		margin-bottom: 61px;

		h1,
		h2,
		h3,
		h4 {

			@include font-size(30px);
			line-height: 42px;
			margin-bottom: 32px;
		}
	}

	.category-teaserbox {

		.colorfilled-layer {

			display: none;

			&.shadow-default {

				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;
			}
		}

		.title {

			@include font-size(24px);
			line-height: 30px;
		}

		.teaserwrapper-small {

			background: $color-ci;
			padding-top: 66px;
			padding-bottom: 12px;
		}

		.box-image {

			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
			&.columns {

				padding-left: 14px;
				padding-right: 14px;
			}

			.teaserimage {

				box-shadow: 0 2px 4px 0 rgba(50, 50, 50, .4);
			}
		}

		.box-text {

			background: transparent;
		}

		&.first {

			margin-top: 51px;
		}

		&.even,
		&.odd {

			margin-bottom: 90px;

			&.row-quotation-following {

				margin-bottom: 50px;
			}

			.box-text {
				margin-top: 0;
			}

			.background-circle {

				transform: translate(-50%,-50%);
			}
		}

		/* &.even {

			margin-bottom: 90px;

			.box-text {
				margin-top: 0;
			}

			.background-circle {

				transform: translate(-50%,-50%);
			}
		}

		&.odd {

			margin-bottom: 90px;

			&.row-quotation-following {

				margin-bottom: 50px;
			}

			.box-text {
				margin-top: 0;
			}

			.background-circle {

				transform: translate(-50%,-50%);
			}
		} */

		.innerbox-text-small {

			background: $white;
			margin-left: 6px;
			margin-right: 6px;
			padding-bottom: 80px;
			padding-bottom: 62px;
		}
	}

	.row-zitat {

		&.teaser-before-is-odd,
		&.teaser-before-is-even {

			margin-bottom: 80px;
		}
		.box-zitat {

			h3 {

				@include font-size(32px);
				line-height: 36px;
				margin-bottom: 10px;
			}

			p {

				margin-bottom: 16px;
			}
		}
	}

	.page-lageplan {

		.sizer-lageplan {

			padding-left: $width-outterspace-default-small;
			padding-right: $width-outterspace-default-small;
		}

		.wrapper-lageplan {

			margin-bottom: 20px;
		}
		.copytext-footer {
			padding-top: 6px;
		}

		.map-info-number {

			position: relative;
			display: inline-block;
			width: 22px;
			height: 22px;
			border-radius: 50%;
			background: #004b34;

			.map-number {

				display: inline-block;
				position: absolute;
				left: 50%;
				transform: translate(-50%,-50%);
				top: 50%;
				color: $white;
				font-weight: $bold;
			}
		}
		.map-item-label {

			display: inline-block;
			font-weight: $bold;
			vertical-align: text-bottom;
			padding-left: 6px;
		}
		.line {

			display: inline-block;
			padding-left: 28px;
			padding-right: 8px;
			line-height: 15px;
			vertical-align: text-top;
			margin-bottom: 6px;
		}
	}

	.archive-zimmer,
	.archive-arrangement {

		.column-fotobox {

			margin-bottom: 40px;
			padding-left: $width-outterspace-default-small;
			padding-right: $width-outterspace-default-small;
		}
	}

	.single-zimmer,
	.single-arrangement {

		.sizer {

			padding-left: $width-outterspace-default-small;
			padding-right: $width-outterspace-default-small;
		}

		.box-previous-link {

			padding-left: $width-outterspace-default-small;
		}
		.box-next-link {

			padding-right: $width-outterspace-default-small;
		}
	}

	.wrapper-room-details,
	.wrapper-room-details-dynamic {

		.row-room-data,
		.row-room-description {

			position: relative;
			margin-left: 0;
			margin-right: 0;
		}

		.button {

			bottom: 30px;
		}

		.wrapper-slider {

			//padding-top: 8px;
		}

		.wrapper-details {

			padding-bottom: 60px;
			padding-left: 0;
			padding-right: 0;

			.title-facilities,
			.title-services {

				padding-left: $width-outterspace-default-small;
				padding-right: $width-outterspace-default-small;
				padding-bottom: 10px;
			}

			.column-icon {

				span {

					display: block;
					width: 42px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}

		.column-description {

			.sizer {

				border: none;
				padding-left: $width-outterspace-default-small;
				padding-right: $width-outterspace-default-small;
			}

			.title {

				@include font-size(36px);
				line-height: 40px;
				margin-bottom: 24px;
			}
		}
		.column-prices {

			.sizer {

				margin: 0;
				padding-bottom: 100px;
				padding-left: $width-outterspace-default-small;
				padding-right: $width-outterspace-default-small;
			}
		}
	}

	.wrapper-room-details-dynamic {

		.wrapper-details {

			padding-left: $width-outterspace-default-small;
			padding-right: $width-outterspace-default-small;
		}

		.trans-placeholder {

			height: 185px;
		}

		.column-prices {

			.sizer {

				padding-bottom: 30px;
			}
		}
	}

	.page-slider {

		.wrapper-sliderarea {

			.sizer {

				padding-left: $width-outterspace-default-small;
				padding-right: $width-outterspace-default-small;
			}

			.title {

				@include font-size(24px);
				line-height: 32px;
				margin-bottom: 8px;
			}

			.subtitle {

				@include font-size(18px);
				margin-bottom: 18px;
			}

			.slide-caption {

				line-height: 20px;
			}
		}
	}

	.row-introtext-content-top,
	.row-introtext-content-top-inner {

		padding-bottom: 0;
		padding-left: $width-outterspace-default-small;
		padding-right: $width-outterspace-default-small;
	}

	.page-kulinarischer-kalender {

		.box-introtext-month {

		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 50px;

		.headline-introtext-month {

			@include font-size(18px);
			margin-bottom: 8px;
		}

		p {
			@include font-size(14px);
			margin-bottom: 18px;
		}
	}

		.headline-month {

			//@include font-size(15px);
			@include font-size(22px);
			margin-bottom: 4px;
		}

		.headline {

			@include font-size(24px);
			line-height: 32px;
			margin-bottom: 8px;
		}

		.subheadline {

			@include font-size(18px);
			margin-bottom: 18px;
		}

		.sizer-headlines,
		.sizer-left,
		.sizer-right {

			padding-left: $width-outterspace-default-small;
			padding-right: $width-outterspace-default-small;
		}

		.event-image {

			margin-bottom: 14px;
		}

		.row-event {

			margin-bottom: 80px;
		}
	}

	.page-content-blocks {

		.title-copytext-image-2-cols {

			.wrapper-headlines {

				padding-left: $width-outterspace-default-small;
				padding-right: $width-outterspace-default-small;
			}

			.sizer-img-right,
			.sizer-img-left {

				padding-left: $width-outterspace-default-small;
				padding-right: $width-outterspace-default-small;
			}

			.headline {

				@include font-size(24px);
				line-height: 32px;
				margin-bottom: 8px;
			}
			.subheadline {

				@include font-size(18px);
				line-height: 28px;
				margin-bottom: 18px;
			}

			.box-image {

				.sizer-img-right,
				.sizer-img-left {

					margin-bottom: 14px;
				}
			}
		}
	}

	.page-kontakt-anreise {

		.sizer-left,
		.sizer-right {

			padding-left: $width-outterspace-default-small;
			padding-right: $width-outterspace-default-small;
		}

		.sizer-right {

			margin-top: 30px;
		}

		.headline-form {

			@include font-size(24px);
			line-height: 32px;
			margin-bottom: 8px;
		}

		.subheadline-form {

			@include font-size(18px);
			line-height: 28px;
			margin-bottom: 18px;
		}

		.button-submit {

			margin-right: $width-outterspace-default-small;
		}
	}

	// HOME SLIDER
	.on-home .slick-prev,
	.on-home .slick-next {

		width: 21px;
		height: auto;
		bottom: 18px;
	}
	.on-home .slick-prev {

		left: 16px;
		right: auto;
	}
	.on-home .slick-next {

		left: auto;
		right: 16px;
	}

	.page-archive-unsere-serviceleistungen {

		.archive-iconbox {

			margin-left: $width-outterspace-default-small;
			margin-right: $width-outterspace-default-small;
		}
	}
	.archive-unsere-serviceleistungen {

		.sizer {

			padding-left: $width-outterspace-default-small;
			padding-right: $width-outterspace-default-small;
		}
	}

	.page-kontakt-anreise {

		.button {

			margin-bottom: 14px;
		}
	}

	// --------------------------------------
	// FOOTER:
	.wrapper-footer {

		.label-navsection {

			/* @include font-size(14px);
			color: $white;
			margin-bottom: 4px;
			font-family: $font-family-lato;
			font-weight: $bold; */
			padding-left: $width-outterspace-default-small;
			padding-right: $width-outterspace-default-small;
		}

		/* a {

			@include font-size(14px);
			line-height: 16px;
			font-weight: $light;
			color: $white;
		} */

		.quicknavi {

			//padding-top: 90px;

			.navsection {

				//margin-bottom: 30px;
			}

			.ftr-navitem {

				padding-left: $width-outterspace-default-small;
				padding-right: $width-outterspace-default-small;
				line-height: 10px;
				margin-bottom: 10px;
			}
		}
	}
}

@include breakpoint(small up) {
	/* von kleinst bis unendlich */
	.small-up { color: $white; }
}

@include breakpoint(medium only) {
	/* min 641 bis max 1023 */
	.medium-only { color: $white; }

	@include breakpoint(portrait) {
		// CSS for landscape-oriented devices only

		.archive-arrangement,
		.archive-zimmer {

			.archive-fotobox {

				.title {

					line-height: 23px;
				}

				.title-link {

					@include font-size(18px);
					line-height: inherit;
				}

				.subline {

					@include font-size(12px);
					padding-right: 0;
				}

				.details {

					@include font-size(12px);
				}
			}
		}

		.wrapper-room-details {

			.wrapper-details {

				.icon {

					font-size: 22px;
				}
			}
		}
	} // medium-only portrait
}

@include breakpoint(medium up) {
	/* min 641 bis unendlich */
	.medium-up { color: $white; }
}

@include breakpoint(large up) {
	/* min 1024 bis unendlich */
	.large-up { color: $white; }
}
