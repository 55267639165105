
.pagination {

	display: flex;
	flex-direction: row;
	justify-content: center;

	.pagination-item {

		@include font-size(16px);
		color: $color-blog-pagination;
		font-style: italic;
		display: inline-block;
		padding: 4px 12px;
		border: 1px solid $color-border-pagination-item-normal;

		&.previous,
		&.next,
		&.dots {

			text-decoration: none;
		}

		&.previous {

			margin-right: $margin-pagination-item;
		}

		&.pagination-link {

			margin-left: $margin-pagination-item;
			margin-right: $margin-pagination-item;
		}

		&.next {

			margin-left: $margin-pagination-item;
		}

		&.is-current {

			background-color: $color-white;
		}

		&:hover {

			background-color: $color-white;
			cursor: pointer;
		}

		&.disabled,
		&.dots {
			background-color: transparent;
			cursor: default;
			border: 1px solid $color-border-pagination-item-inactive;
			color: $color-border-pagination-item-inactive;
		}
	}

	.pagination-list,
	.pagination-list-item {

		display: inline-block;
	}
}
