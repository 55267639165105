@charset 'UTF-8';

.wrapper-room-details .slick-slider,
.wrapper-room-details-dynamic .slick-slider {

	margin-bottom: 0;
}




.slick-dots {

	/*max-width: 1170px;*/
	bottom: 20px;
}

.slick-dots li {
    margin: 0;
}

.slick-dots li button:before {
	opacity: .25;
	color: #f7f6f2;
	font-size: 12px;
}
.slick-dots li.slick-active button:before {
	/*opacity: .75;*/
	color: #ffffff;
	font-size: 12px;
}

.slick-prev::before,
.slick-next::before {

    content: '';
}

.slick-prev,
.slick-next {
	top: 50%;
	transform: translateY(-50%);
    opacity: 1;
}

.slick-prev {
    width: 21px;
    height: 42px;
    background: transparent url('../../../images/icon/nav-slider-left.png') no-repeat;
    left: 0px;
    right: auto;
    z-index: 10;
}

.slick-next {
    width: 21px;
    height: 42px;
    background: transparent url('../../../images/icon/nav-slider-right.png') no-repeat;
    left: auto;
    right: 0;
    z-index: 10;
}

.slick-prev:focus,
.slick-prev:hover {
    color: transparent;
    outline: 0;
    background: transparent url('../../../images/icon/nav-slider-left.png') no-repeat;
}

.slick-next:focus,
.slick-next:hover {
    color: transparent;
    outline: 0;
    background: transparent url('../../../images/icon/nav-slider-right.png') no-repeat;
    opacity: 0.6;
}

/* HOME HEADSLIDER: */
.on-home .slick-prev::before,
.on-home .slick-next::before {

    content: '';
}

.on-home .slick-prev,
.on-home .slick-next {

    background: transparent url('../../../images/svg/pfeil-weiss.svg') no-repeat;
    width: 21px;
    height: 42px;
    bottom: 23px;
    top: auto;
    transform: none;
    opacity: 1;
    z-index: 10;
}

.on-home .slick-prev {

    transform: rotate(180deg);
    left: 30px;
    right: auto;
    /*background: transparent url('../../../images/svg/pfeil-weiss.svg') no-repeat;*/
    /* width: 21px;
    height: 42px;*/
    /*z-index: 10;*/
}

.on-home .slick-next {

    left: auto;
    right: 30px;
    /*background: transparent url('../../../images/svg/pfeil-weiss.svg') no-repeat;*/
    /*width: 21px;
    height: 42px;*/
    /*z-index: 10;*/
}

.on-home .slick-prev:focus,
.on-home .slick-prev:hover {
    color: transparent;
    outline: 0;
    background: transparent url('../../../images/svg/pfeil-weiss.svg') no-repeat;
    transform: rotate(180deg);
}

.on-home .slick-next:focus,
.on-home .slick-next:hover {
	color: transparent;
	outline: 0;
	background: transparent url('../../../images/svg/pfeil-weiss.svg') no-repeat;
   /* opacity: 0.6;*/
}

.default-slider .slick-dots {
	bottom: 50px;
}

/* Slider defaults: */
/*.slick-loading .slick-list
{
    background: #fff url('./ajax-loader.gif') center center no-repeat;
}*/

/* Icons */
/*@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url('./fonts/slick.eot');
    src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
}*/
/* Arrows */
/*
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    margin-top: -10px\9;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}*/

/* Dots */
/*.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -45px;

    display: block;

    width: 100%;
    padding: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}*/
