@charset "utf-8";

body {
	position: relative;
	// z-index: 0;
}
// define max row-width for foundation here:
/* .row { max-width: 980px; } */

// img { width:100%; height: auto; } // allready set by foundation

.logo-top {

	width: 175px;
	padding-top: 15px;
}

.logo-top-small {

	display: none;
}

.stage {

	min-height: 100%;
	height: 100%;
	position: relative;
	// z-index: 3;
	z-index: 4;
	width: 100%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	/* background-image: url('../images/dummies/dummy-head-motiv-1.jpg');
	background-repeat: no-repeat;
	background-color: transparent;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center $height-fixed-header; */

	&.type-small-stage {

		min-height: 0;
		height: $height-header + $height-breadcrumb + $height-headerimage-small;

		/* .container-image { */
		.head-image-container {

			height: 265px;
		}
	}

	&.type-no-image-stage {

		min-height: 0;
		height: 171px;
	}

	&.on-home {

		background-image: none;
	}

	.head-image-container {

		position: absolute;
		top: $height-fixed-header;
		width: 100%;
		//background: #000033;

		//background-image: url('../images/dummies/dummy-head-motiv-1.jpg');	// wird dynamisch inline gesetzt
		background-repeat: no-repeat;
		background-color: transparent;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		background-position: center center;
	}
}
.wrapper-fixed-header {

	position: fixed;
	top: 0;
	left: 0;
	height: $height-fixed-header;
	width: 100%;
	z-index: 10;
}
.wrapper-header {

	position: relative;
	background: $white;
	z-index: 11;

	.row-logo-menu {

		height: $height-row-logo-menu;
	}

	.box-top-navi {

		@include font-size(13px);
		text-align: right;

		a {

			color: $color-topmenu-dark;
		}

		.topmenu-item {

			display: inline-block;
			padding-left: 10px;
			color: $color-topmenu-dark;
		}

		.topmen-lang-spacer {

			display: inline-block;
			width: 10px;
		}

		.btn-langswitch {

			display: inline-block;
			color: $color-topmenu-bright;

			&.active,
			&:hover {

				color: $color-topmenu-dark;
			}
		}

		.lang-spacer {

			display: inline-block;
			text-align: center;
			padding-left: 2px;
			padding-right: 2px;
			color: $color-topmenu-bright;
		}

	}
	.box-main-navi {

		@include font-size(16px);
	}
}
.wrapper-breadcrumb {

	height: 31px;
	background: $color-breadcrumb-green url('../images/patterns/bg-breadcrumb.png') repeat-x;
}
.box-scrolldown {

	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 83px;
}

.wrapper-header-textbox {

	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translatex(-50%);
	width: 620px;
	height: auto;
	padding-bottom: 40px;
	background: rgba(255, 255, 255, .85);

	&.type-small-stage {

		padding-bottom: 0;
	}

	.title {

		@include font-size(40px);
		line-height: 46px;
		text-align: center;
		margin-top: 28px;
		margin-bottom: 20px;
		padding-left: 40px;
		padding-right: 40px;
	}

	p {
		@include font-size(16px);

		font-weight: $light;
		text-align: center;
		padding-left: 80px;
		padding-right: 80px;
	}

	.button {

		@extend %base-button;
		margin: 20px auto;
		display: block;
		max-width: 160px;
		text-align: center;
	}
}

.home-head-slider {

	position: absolute;
	//top: 0;
	top: $height-fixed-header;
	left: 0;
	width: 100%;
	//height: 100%;

	.slide-item {

		width: 100%;
		height: 100%;

		background-image: url('../images/dummies/dummy-head-motiv-1.jpg');	// wird dynamisch inline gesetzt
		background-repeat: no-repeat;
		background-color: transparent;
		background-size: cover;
		//background-position: center $height-fixed-header;
		background-position: center center;
	}

	.slick-list,
	.slick-track {

		height: 100%;
	}
}

.wrapper-header-textbox-home {

	position: absolute;
	bottom: 86px;

	/* right: 50%;
	left: 0;
	width: 50%; */
	height: auto;
	padding-bottom: 30px;
	background: rgba(255, 255, 255, .85);

	&.box-align-right {

		width: 50%;
		left: 50%;
	}

	&.box-align-left {

		right: 50%;
		left: 0;
		width: 50%;
	}

	.overline {

		@include font-size(20px);
		line-height: 24px;
		font-family: $font-family-lato;
		font-weight: $light;
		color: $color-zitat-title;
		font-style: italic;
		margin-top: 28px;
		margin-bottom: 4px;
		padding-left: 30px;
		padding-right: 30px;
	}

	.title {

		@include font-size(40px);
		font-family: $font-family-lato;
		font-weight: $light;
		line-height: 46px;
		margin-top: 10px;
		margin-bottom: 20px;
		padding-left: 30px;
		padding-right: 30px;
	}

	p {
		@include font-size(16px);
		font-weight: $light;
		padding-left: 30px;
		padding-right: 30px;
	}

	.button {

		@extend %base-button;
		margin-left: 30px;
		//display: block;
		//max-width: 160px;
		text-align: center;
	}
}

.row-introtext-content-top,
.row-introtext-content-top-inner {

	padding-top: 30px;
	padding-bottom: 50px;
	text-align: center;
	margin-bottom: 81px;

	&.actpage-home {

		padding-top: 40px;
	}

	&.row {

		max-width: 580px;
		margin-left: auto;
		margin-right: auto;
	}

	h1,
	h2,
	h3,
	h4 {

		@include font-size(60px);
		line-height: 62px;
		margin-bottom: 32px;
	}

	p {

		@include font-size(16px);
		font-weight: $light;
		color: $totalblack;
	}

}

.row-introtext-content-top-inner {

	margin-bottom: 0;
}

.diagonal-stripe-white {

	max-width: 640px;
	margin: 120px auto;
}

.page-category-main {

	.head-content-space {

		/* height: 220px; */
		height: 81px;
	}
}
.page-archive-zimmer,
.page-archive-arrangements,
.page-archive-unsere-serviceleistungen {

	.head-content-space {

		height: 42px;
	}
}

.page-archive-unsere-serviceleistungen {

	.row-introtext-content-top {

		margin-bottom: 30px;
	}
}
// --------------------------------------
// ZIMMER, ARRANGEMENTS:
.archive-zimmer,
.archive-arrangement {

	.bar {

		@extend .shadow-default;
		height: 13px;
		background: $color-ci url('../images/patterns/bg-green-bar.png') repeat-x; // hier noch das pattern hinzufügen !!!
	}

	.column-fotobox {

		margin-bottom: 20px;
	}

	.archive-fotobox {

		@extend .shadow-default;
		background: $white;
		padding-bottom: 40px;
		position: relative;

		.title,
		.subline {

			padding-left: 22px;
			padding-right: 22px;
			font-family: $font-family-lato;
			font-weight: $light;
		}
		.title {

			@include font-size(24px);
			line-height: 28px;
			padding-top: 20px;
			margin-bottom: 20px;
		}

		.title-link {

			@include font-size(24px);
			line-height: 28px;
			color: $color-ci;
			text-decoration: none;
			padding-top: 20px;
			margin-bottom: 20px;
		}

		.bottomline {

			position: absolute;
			bottom: 20px;
			width: 100%;
		}

		.subline {

			@include font-size(14px);
			color: $totalblack;
			//@debug ($black);
			float: left;
		}

		.details {

			@include font-size(14px);
			font-weight: $bold;
			font-style: italic;
			color: $color-archive-details-link;
			padding-right: 22px;
			float: right;
		}
	}

	.row-button-book {

		padding-top: 70px;

		.button {

			@extend %base-button;
			display: block;
			max-width: 160px;
			text-align: center;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
.single-zimmer,
.single-arrangement {

	.head-content-space {

		height: 81px;
	}

	.box-previous-link,
	.box-next-link {

		padding-top: 25px;

		a {

			@include font-size(14px);
			font-weight: bold;
			font-style: italic;
			color: $color-previous-next-links;
		}
	}

	.box-previous-link {

		float: left;
	}
	.box-next-link {

		float: right;
	}

	@include box-back-to-archive($color-border-back-to-archive);
}

.single-zimmer {

	.slick-slide {

		background: $white;
	}
}

.wrapper-room-details {

	@extend .shadow-default;
	background: $white;
	margin-bottom: 80px;
	font-weight: $light;

	.row-room-data,
	.row-room-description {

		position: relative;
	}

	p {

		@include font-size(14px);
	}

	.wrapper-details {

		padding-top: 14px;
		padding-left: 20px;
		padding-right: 20px;

		a {

			@include font-size(14px);
			position: absolute;
			bottom: 12px;
			font-weight: $bold;
			font-style: italic;
			display: inline-block;
			color: $white;
			padding-left: 14px;
		}

		.column {

			margin-bottom: 16px;
		}

		.icon {

			font-size: 30px;
			color: $white;
		}

		.title-facilities,
		.title-services {

			@include font-size(14px);
			display: inline-block;
			padding-left: 14px;
			padding-bottom: 10px;
			font-weight: $bold;
			color: $white;
		}

		.title-services {

			padding-top: 10px;
		}

		// ARRANGEMENTS:
		h3 {

			@include font-size(14px);
			margin-bottom: 8px;
			font-family: $font-family-lato;
			font-weight: $bold;
			color: $white;
		}

		p {
			color: $white;
		}
	}

	.column-description {

		.sizer {

			margin: 30px 0;
			padding-top: 14px;
			padding-left: 40px;
			padding-right: 40px;
			border-right: $color-border-zimmer-txt-line 1px solid;
		}

		.title {

			@include font-size(40px);
			margin-bottom: 34px;
			font-family: $font-family-lato;
			font-weight: $light;
		}
	}

	.column-prices {

		.sizer {

			margin: 30px 0;
			padding-top: 14px;
			padding-left: 30px;
			padding-right: 26px;
			padding-bottom: 60px;
		}
	}

	.price-line-box {

		@include font-size(14px);


		.price-label,
		.price {

			display: inline-block;
		}

		.price-label {

			float: left;
		}

		.price {

			float: right;
		}
	}

	.box-optional-prices {

		margin-top: 20px;
	}

	.button {

		@extend %base-button;
		margin: 0;
		position: absolute;
		bottom: 40px;
		right: 30px;
	}

	.slick-slide img {

		margin-left: auto;
		margin-right: auto;
		width: auto;
		max-height: 421px;
	}
}

// Text unten, zB Arrangements Archive:
.box-page-bottom-text {

	padding-top: 72px;

	p {

		@include font-size(16px);
		margin-bottom: 8px;
		font-family: $font-family-lato;
		font-weight: $bold;
		font-style: italic;
		color: $color-bottom-text;
		text-align: center;
	}
}

.archive-unsere-serviceleistungen {

	text-align: center;

	.sizer {

		padding-left: 10px;
		padding-right: 10px;
	}

	.archive-iconbox {

		@extend .shadow-default;
		background: $white;
		margin-bottom: 60px;
		padding-bottom: 20px;
		position: relative;
	}

	.title {

		@include font-size(20px);
		font-family: $font-family-lato;
		font-weight: $light;
		padding-top: 70px;
		margin-bottom: 20px;
	}

	p {

		@include font-size(14px);
		line-height: 22px;
		font-family: $font-family-lato;
		font-weight: $light;
		color: $totalblack;
	}

	.background-circle {

		@extend .shadow-default;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		top: 0;
		width: $width-icon-category-teaser;
		height: $width-icon-category-teaser;
		border-radius:50%;
		background: $color-ci;
		z-index: 2;
	}

	.icon-teaser {

		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: $width-icon-category-teaser;
		height: $width-icon-category-teaser;
		z-index: 3;

		[class*=" icon-"]:before,
		[class^=icon-]:before {

			@include font-size(40px);
			position: absolute;
			display: block;
			width: 100%;
			top: 0;
			bottom: 0;
			transform: translateY(25%);
			margin: 0;
			margin-left: 0;
			margin-right: 0;
			color: $white;
			text-align: center;
			z-index: 2;
		}
	}
}

.page-kontakt-anreise {

	.wrapper-contentblockarea {

		@extend .shadow-default;
		background: $white;

		.row-txt-map {

			padding-top: 48px;
		}

		label {

			@include font-size(14px);
			font-family: $font-family-lato;
			font-weight: $light;
			color: $totalblack;
		}
	}

	p {
		@include font-size(14px);
		font-family: $font-family-lato;
		font-weight: $light;
		color: $totalblack;
	}

	.sizer-left {

		padding-left: 40px;
		padding-right: 28px;
	}

	.sizer-right {

		padding-left: 28px;
		padding-right: 40px;
	}

	.row-spacer {

		height: 70px;
	}

	.headline-form {

		@include font-size(32px);
		font-family: $font-family-cinzel-decorative;
		margin-bottom: 5px;
	}
	.subheadline-form {

		@include font-size(20px);
		font-family: $font-family-lato;
		font-weight: $light;
		color: $color-subtitle-slider;
		margin-bottom: 25px;
	}

	.wrapper-form {

		position: relative;
		//padding-bottom: 80px;
	}

	.wpcf7 textarea
	{
		margin: 0;
		height: 204px;
	}

	span.wpcf7-not-valid-tip {
		// defaults:
		color: #f00;
		font-size: 1em;
		display: block;
	}

	div.wpcf7-validation-errors {

		border: 2px solid $color-ci;
	}

	div.wpcf7-response-output {
		margin: 2em 0.5em 1em;
		padding: 0.2em 1em;
		@include font-size(14px);
	}

	.wpcf7-email {

		margin-bottom: 0;
	}

	.button-submit {

		border:none;
		float: right;
		margin-right: 40px;
	}

	.button {

		@extend %base-button;
		margin-top: 25px;
		margin-bottom: 34px;
		padding: 6px 20px;
		display: inline-block;
		max-width: 160px;
		text-align: center;
	}



	.frm-label-left,
	.frm-label-right {

		@include font-size(14px);
		font-family: $font-family-lato;
		font-weight: $light;
		color: $totalblack;

		display: inline-block;
		width: 50%;
		float: left;
	}

	.frm-label-left {

		display: inline-block;
		width: 50%;
		float: left;
	}

	.frm-label-right {

		text-align: right;
		float: right;
	}
}

.page-lageplan {

	.wrapper-contentblockarea {

		@extend .shadow-default;
		background: $white;
	}

	.introtext {

		padding-top: 30px;
		padding-bottom: 30px;

		p {

			@include font-size(20px);
			font-family: $font-family-lato;
			font-weight: $light;
			color: $color-introtext-lageplan;
			margin-bottom: 0;
		}
	}

	.sizer-lageplan {

		padding-left: 42px;
		padding-right: 42px;
	}

	.wrapper-lageplan {

		position: relative;
		border: #b2b2b2 1px solid;
	}

	.copytext-footer {

		padding-top: 26px;

		p {
			@include font-size(14px);
			font-family: $font-family-lato;
			font-weight: $light;
			color: $totalblack;
		}

		a {
			text-decoration: underline;
		}
	}

	.map-item {

		position: absolute;
	}

	.box-mapinfo-phones {

		@include font-size(14px);
		font-family: $font-family-lato;
		font-weight: $light;
		color: $totalblack;

		margin-bottom: 20px;
	}
}

.page-kulinarischer-kalender {

	.row-introtext-content-top-inner {

		p:last-child {

			margin-bottom: 0;
		}
	}

	.box-headline-month {

		max-width: 560px;
		margin-left: auto;
		margin-right: auto;
	}

	.box-introtext-month {

		width: 560px;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 50px;

		.headline-introtext-month {

			@include font-size(20px);
			margin-bottom: 8px;
			font-family: $font-family-lato;
			font-weight: $light;
			color: $color-headline-kalenderevent;
			text-align: center;
		}

		p {
			@include font-size(16px);
			font-weight: $light;
			color: $totalblack;
			text-align: center;
			margin-bottom: 20px;
		}
	}

	.headline-month {

		@include font-size(30px);
		font-family: $font-family-lato;
		font-weight: $light;
		color: $color-headline-month;
		font-style: italic;
		text-align: center;
		margin-bottom: 22px;
		cursor: pointer;
		cursor: hand;
	}

	.wrapper-events-of-month {

		//padding-top: 60px;
		padding-top: 20px;

		&:not(.first-month) {

			display: none;
		}
	}

	.row-event {

		@extend .shadow-default;
		position: relative;
		background: $white;
		padding-top: 68px;
		padding-bottom: 38px;
		margin-bottom: 100px;
	}

	.background-circle {

		@extend .shadow-default;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%, -50%);
		width: $width-icon-category-teaser;
		height: $width-icon-category-teaser;
		border-radius: 50%;
		background: $color-ci;
		z-index: 2;
	}

	.updown {

		width: 42px;

		&.up {

			@include rotate(180deg);
			margin-bottom: 8px;
		}
	}

	.box-date {

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.day-start,
	.day-end,
	.day-divider {

		@include font-size(28px);
		font-family: $font-family-lato;
		font-weight: $light;
		display: block;
		text-align: center;
		color: $white;
	}
	.day-divider {

		line-height: 9px;
	}

	.sizer-headlines {

		padding-left: 40px;
		padding-right: 40px;
	}
	.sizer-left {

		padding-left: 40px;
		padding-right: 28px;

		.event-image {

			padding-top: 10px;
		}
	}

	.sizer-right {

		padding-left: 28px;
		padding-right: 40px;

		p {

			@include font-size(14px);
			font-family: $font-family-lato;
			font-weight: $light;
			color: $totalblack;
		}

		p:last-of-type {

			margin-bottom: 0;
		}
	}

	.headline {

		@include font-size(40px);
		line-height: 42px;
		margin-bottom: 5px;

		&.no-subheadline {

			margin-bottom: 25px;
		}
	}

	.subheadline {

		@include font-size(20px);
		margin-bottom: 25px;
		font-family: $font-family-lato;
		font-weight: $light;
		color: $color-headline-kalenderevent;
	}

	.box-bottom-data {

		padding-top: 18px;

		.column {

			&.column-label {

				width: 42%;

				@include breakpoint(small only) {

					width: 56%;
				}

				@include breakpoint(medium only) {

					width: 50%;
				}
			}

			&.column-data {

				width: 58%;

				@include breakpoint(small only) {

					width: 44%;
				}

				@include breakpoint(medium only) {

					width: 50%;
				}
			}
		}

		.label,
		.data {

			line-height: 14px;
		}
		.label {

			@include font-size(14px);
			font-family: $font-family-lato;
			font-weight: $bold;
			color: $totalblack;
			display: inline-block;
			width: 170px;
		}
		.data {

			@include font-size(14px);
			font-family: $font-family-lato;
			font-weight: $light;
			color: $totalblack;
		}
	}
}

.page-slider {

	.row {

		&.row-slidercontainer {

			max-width: 940px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.wrapper-sliderarea {

		@extend .shadow-default;
		background: $white;

		.no-title-space {

			height: 46px;
		}

		.sizer {

			padding-left: 42px;
			padding-right: 42px;
		}

		.title {

			@include font-size(32px);
			font-family: $font-family-cinzel-decorative;
			margin-bottom: 5px;
		}
		.subtitle {

			@include font-size(20px);
			font-family: $font-family-lato;
			font-weight: $light;
			color: $color-subtitle-slider;
			margin-bottom: 25px;
		}

		.slide-caption {

			@include font-size(14px);
			font-family: $font-family-lato;
			font-weight: $light;
			color: $totalblack;
			padding-top: 8px;
			margin-bottom: 10px;
		}
	}

	.slick-slide img {

		margin-left: auto;
		margin-right: auto;
		width: auto;
		max-height: 538px;
	}

	.slider-bottomline {

		height: 1px;
		margin-bottom: 64px;
		background: $color-slider-bottomline;
	}

}

.page-buchen {

	.wrapper-contentblockarea {

		@extend .shadow-default;
		background: $white;
	}

	.row-buchen {

		padding-top: 81px;
		padding-bottom: 34px;
	}

	p {
		@include font-size(14px);
		font-family: $font-family-lato;
		font-weight: $light;
		color: $totalblack;
	}

	.sizer-left {

		padding-left: 40px;
		padding-right: 28px;
	}

	.sizer-right {

		padding-left: 28px;
		padding-right: 40px;
	}

	/* .wrapper-form {

		padding-top: 80px;
		padding-bottom: 80px;
		padding-left: 40px;
		padding-right: 40px;

		img {

			width: auto;
			height: auto;
		}
	} */
}

.wrapper-switch-hotel-bar {

	background-color: $color-ci-klostermuehle;
	padding-bottom: 24px;

	.switch-hotel-bar {

		color: $white;
		padding-top: 14px;
		text-align: center;

		.label {

			display: inline-block;
			padding-top: 12px;
			padding-right: 20px;
		}
	}

	.logo-switch-hotel-bar {

		display: inline-block;
		vertical-align: top;
		width: 266px;
	}
}

.page-buchen-d21 {

	.plaintext-1-2-cols {

		padding-bottom: 100px;
	}
}
#d21-quickbook {

	img {

		width: auto;
	}
	max-width: 580px;

	#d21-headline-container {

		h2 {

			@include font-size(40px);
			margin-bottom: 40px;
			text-align: center;
		}
	}
}

// --------------------------------------
// FOOTER:
.wrapper-footer {

	@extend .shadow-default;

	position: relative;
	//margin-top: 180px;
	margin-top: 140px;
	width: 100%;
	height: auto;
	background: $color-ci;

	.circle-footerlogo {

		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%,-50%);
		width: 85px;
		height: 85px;
		border-radius:50%;
		color:#fff;
		background: $color-ci;
		z-index: 1;
	}

	.footerlogo {

		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%,-20px);
		width: 140px;
		height: 140px;
		z-index: 2;
	}

	a {

		@include font-size(14px);
		//line-height: 16px;
		font-weight: $light;
		color: $white;
	}

	.label-navsection {

		a {

			@include font-size(14px);
			color: $white;
			margin-bottom: 4px;
			font-family: $font-family-lato;
			font-weight: $bold;
			position: relative;
			z-index: 3;
		}
	}

	.quicknavi {

		padding-top: 90px;

		.navsection {

			margin-bottom: 30px;
		}

		.ftr-navitem {

			display: block;
			line-height: 14px;
			margin-bottom: 8px;
		}
	}
}

.switch-hotel-modal {

	display: none;
	position: fixed;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	width: 360px;
	background-image: url('../images/svg/bruening-logo-km-farbe.svg');
	background-repeat: no-repeat;
	background-color: rgba(255, 255, 255, .9);
	background-size: 220px auto;
	background-position: center 20px;
	color: #000000;
	font-weight: $light;
	text-align: center;
	box-shadow: 0 2px 4px 0 rgba(50, 50, 50, .4);
	z-index: 5;

	@include breakpoint(small only) {
		background-size: 160px auto;
		top: 150px;
		left: 50%;
		transform: translateY(0);
		transform: translateX(-50%);
		width: 80%;
	}

	@include breakpoint(medium only) {
		top: 180px;
		transform: translateY(0);
	}

	.button-close {

		position: absolute;
		display: inline-block;
		top: 2px;
		left: 8px;
		padding-top: 4px;
		padding-bottom: 4px;
		padding-left: 6px;
		padding-right: 6px;
		text-decoration: none;
	}

	.container-copytext {

		@include font-size(16px);
		padding-top: 140px;

		@include breakpoint(small only) {
			@include font-size(14px);
			padding-top: 100px;
		}
	}

	.button {

		@extend %base-button;
		margin: 20px auto;
		text-align: center;
	}
}

.module-book-now-button {
	display: inline-block;
	position: fixed;
	bottom: 0;
	left: 11%;
	background-color: rgba(130, 165, 11, 1);
	color: $color-white;
	z-index: 1000;
	padding-left: 10px;
	padding-right: 10px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	padding: 4px 10px;
	transition: background-color 200ms ease-in;

	&:hover {
		background-color: rgba(130, 165, 11, .8);
		text-decoration: none;
	}

	@include breakpoint(medium) {
		left: 20%;
	}

	@include breakpoint(large) {
		left: 25%;
	}
}


.mm-slideout {
	z-index: 3;
}

.close-mm { display: none; }
// --------------------------------------
// foundaion:
.has-tip {
	border-bottom: none;
	/* position: relative;
	display: inline-block;
	cursor: help; */
}
// --------------------------------------
