@charset "utf-8";

html {
	font-size: 100%;
	height: 100%;
	width: 100%;
}


body {

	height: 100%;
	width: 100%;
	background: #transparent url('../images/patterns/body-vertical-stripe-grey.png') top center repeat-y;
	color: $font-color-body;
 }

h1,
h2,
h3,
h4,
h5,
h6 {

	margin-top: 0;
	font-family: $font-family-heading-default;
	color: $font-color-h1;
}

h1 {

	@include font-size($font-size-h1-large);
	margin-bottom: $font-size-h1-large * 1.5;
}
h2 {

	@include font-size($font-size-h2-large);
	margin-bottom: $font-size-h1-large * 1.5;
}

h3 {

	@include font-size($font-size-h3-large);
	margin-bottom: $font-size-h1-large * 1.5;
}

strong,
b {
	font-weight: $bold;
}

i,
em {

	font-style: italic;
}

p {

	font-size: $font-size-body-large;
	line-height: $line-height-body-large;
	margin-bottom: $font-size-body-large * 1.5;
}

img {
	width: 100%;
	max-width: 100%;
	height: auto;
	-ms-interpolation-mode: bicubic;
	display: inline-block;
	vertical-align: middle;
}

a {

	color: $font-color-links;
	text-decoration: none;

	&:hover {

		text-decoration: underline;
	}
}

// mobile phones
a[href^='tel']:link,
a[href^='tel']:visited,
a[href^='tel']:hover {

	text-decoration: none;
	color: $font-color-links;
}

.preloader {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	top: 35%;
	margin: 0 auto;
	width: 72px;
	height: 72px;
	background: transparent url('../images/preloaders/preloader-white.gif') no-repeat;
}

.burger-button {

	//@if variable-exists(burger) {
		display: none; // !important
		position: fixed;
		top: 0;
		right: 66px;
		z-index: 5;

		.burger-icon {

			@include burger(25px, 3px, 5px, $color-burger-button);

		}

		.menu-button {

			display: inline-block;
			padding: .5em;
		}
	//}
}
.mm-opened {

	.burger-icon {

		@include burger-to-cross;
	}
}


/**
 * Styling für das ACF oEmbed Feld (responsive Video Einbindung)
 * <div class="embed-container"><iframe src="https://www.youtube.com/embed/L0ULM6xPH2Y" width="560" height="315" frameborder="0"></iframe></div>
 */
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;	// bei Problemen height: auto; nachfolgen lassen
	overflow: hidden;
	max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


.wrapper-breadcrumb {

	// Farbe Doppelpfeile:
	color: $white;
	font-size: 13px;

	// Farbe Links:
	a {
		font-size: 13px;
		color: $white;
	}

	// Farbe letztes breadcrumb Segmant - kein Link:
	.breadcrumb_last {

		font-size: 13px;
		color: $white;
	}
}

// To clear floats, add the class .clearfix to the parent element.
.clearfix::before,
.clearfix::after {

	content: ' ';
	display: table;
}
.clearfix::after {

	clear: both;
}

:focus {

	outline: 0;
	border: 0;
}


// VERTICAL ALIGN IN ROW ( beware of float reset! )
// ------------------------------------------------
.valign-middle {

	display: table;

	> .columns {
		display: table-cell;
		vertical-align: middle;
	}

	> .columns,
	> [class*="column"] + [class*="column"]:last-child {
		float: none;
	}
}

// GOOGLE MAPS
.map-wrapper {
	position: relative;
	margin-top: 0;
	padding-bottom: 56.25%;
	padding-top: 1.5625rem;
	height: 0;
}
.maps-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/*
.clearfix > is set allready by foundation
 */
