@charset "utf-8";

$padding-left-box: 30px;
$padding-right-box: $padding-left-box;
//$button-color-green: #82a50b;

.category-teaserbox {

	position: relative;

	.teaserbox {

		z-index: 1;
		position: relative;
	}

	.title {

		@include font-size(40px);
		font-weight: $light;
		line-height: 50px;
		font-family: $font-family-lato;
		padding-top: 15px;
		padding-left: 30px;
		padding-right: 30px;
		margin-bottom: 20px;
	}

	&:not(.first) {

		//margin-top: 160px;
	}

	.boxicon {
		position: absolute;
		width: 93px;
		height: 93px;
		z-index: 2;
		left: 50%;

		&.bed {

			background: transparent url('../images/dummies/dummy-teasericon-bett.png') no-repeat;
		}
		&.freizeit {

			background: transparent url('../images/dummies/dummy-teasericon-freizeit.png') no-repeat;
		}
	}

	/* .teasericon-top {
		position: absolute;
		top: 0;
		left: 50%;
		border-radius:50%;
		background: $white;
		width: $width-icon-category-teaser;
		height: $width-icon-category-teaser;
		z-index: 2;
	} */

	.background-circle {

		@extend .shadow-default;
		position: absolute;
		left: 50%;
		top: 0;
		width: $width-icon-category-teaser;
		height: $width-icon-category-teaser;
		border-radius:50%;
		background: $white;
		z-index: 2;
	}

	.icon-teaser {

		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: $width-icon-category-teaser;
		height: $width-icon-category-teaser;
		z-index: 3;

		.icon {

			text-rendering: auto;
			height: 40px;
			line-height: 40px;
			font-size: 40px;
		}

		.bg-svg {

			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 52px;
			height: 40px;
			background: transparent url('../images/svg/iconset/safe.svg') no-repeat;
			background-size: cover;
		}

		.svgimg {

			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 52px;
			height: auto;
		}

		[class*='icon-']:before{

			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 25%);

			color: $color-icon-category-teaser;
			@include font-size(40px);
		}

		/* [class*=" icon-"]:before,
		[class^=icon-]:before {

			@include font-size(40px);
			//position: absolute;
			position: relative;
			display: block;
			width: 100%;
			//top: 0;
			top: 20px;
			bottom: 0;
			//transform: translateY(25%);
			margin: 0;
			margin-left: 0;
			margin-right: 0;
			color: $color-icon-category-teaser;
			text-align: center;
			z-index: 2;
			//background: #ff0000;
		} */

		.fa-camera-retro:before {
			font-size: 40px;
		}
	}

	.colorfilled-layer {

		position: absolute;
		top: 0;
		bottom: 25%;
		background: $color-ci;
		z-index: -1;
	}

	.box-image,
	.box-text {

		@extend .shadow-default;
		position: relative;
		z-index: 2;
	}

	.box-text {

		background: $white;
		//box-shadow: 0 2px 4px 0 rgba(50,50,50,.4);

		p {

			padding-left: $padding-left-box;
			padding-right: $padding-right-box;
			@include font-size(14px);
			line-height: 26px;
			font-weight: 300;
		}

		.button {

			@extend %base-button;
			margin-right: $padding-right-box;
			margin-bottom: 32px;
			float: right;
		}
	}

	&.first {

		margin-top: 81px;
	}

	&.even {

		margin-bottom: 90px;

		&.row-quotation-following {

			margin-bottom: 100px;
		}

		.icon-teaser {

			//transform: translate(-50%, 0);
		}

		.boxicon,
		.background-circle {

			transform: translate(-50%, -125px);
		}

		.colorfilled-layer {

			left: 8.33%;
			right: 0;
			transform: translate( 0, -85px);
		}

		.box-text {

			margin-top: 80px;
		}

		.box-image {

			//box-shadow: -2px 2px 5px 0 rgba($shadow-r, $shadow-g, $shadow-b, $alpha-rgb-shadows);
			//box-shadow: 0 2px 4px 0 rgba(50,50,50,.4);
		}
	}

	&.odd {

		margin-bottom: 180px;

		&.row-quotation-following {

			margin-bottom: 100px;
		}

		.boxicon,
		.background-circle {

			transform: translate(-50%, -43px);
		}

		.colorfilled-layer {

			left: 0;
			right: 8.33%;
			transform: translate( 0, 85px);
		}

		.box-text {

			margin-top: 160px;
		}

		.box-image {

			//box-shadow: 2px 2px 5px 0 rgba(50, 50, 50, $alpha-rgb-shadows);
			//box-shadow: 0 2px 4px 0 rgba(50,50,50,.4);
		}
	}
}
.row-zitat {

	/* margin-bottom: 160px; */
	margin-bottom: 130px;

	&.teaser-before-is-odd {

		margin-bottom: 210px;
	}

	.box-zitat {

		max-width: 580px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;

		h3 {

			@include font-size(60px);
			line-height: 60px;
			color: $color-zitat-title;
			margin-bottom: 20px;
		}
		p {

			font-style: italic;
			color: $color-zitat-copytext;
		}

		p:last-of-type {

			margin-bottom: 0;
		}

		.author {

			@include font-size(16px);
			font-weight: $light;
			color: $totalblack;
		}
	}


}
