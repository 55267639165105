@charset "utf-8";

.mm-menu {
	>.mm-navbar {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 20px;
		background-color: rgba(220, 220, 220, 1);
	}
}

.mm-navbar-bottom {

	height: auto;

	.box-hotel-link {
		background-color: rgba(220, 220, 220, 1);
	}

	.headline {
		@include font-size(16px);
		display: inline-block;
		font-weight: $bold;
		color: $color-ci;
	}

	a.lnk-hotel-link {
		@include font-size(14px);
		font-weight: $regular;
		color: $color-ci;
	}
}

@include breakpoint(medium up) {
	/* min 641 bis unendlich */


.wrapper-menu-main {

	margin-top: $margin-top-menu-main;

	a {

		font-family: $font-family-lato;
		color: $color-menu-main-normal;

		&.box-menuicon {

			height: 26px;

			.menuicon {

				height: 26px;
			}
		}
	}

	ul {

		position: relative;
		float: right;
		list-style: none;
		margin: 0;
		padding-left: 0;

		// li first level:
		.nav-main-item {

			width: 84px;

			.icon::before {

				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				margin-left: auto;
				margin-right: auto;
				//font-family: $font-family-icon;
				//font-family: 'Glyphter';
				font-family: $font-family-icon;
				font-size: 26px;
				color: $color-ci;
				width: auto;
				cursor: hand;
				cursor: pointer;
			}
		}

		// link first level:
		.nav-main-link {

			@include font-size(16px);
			display: block;
			//padding-top: 24px;
			padding-top: 1px;
			width: 100%;
			text-align: center;
		}

		li {

			//font-family: 'forsthaus-menu';
			position: relative;
			display: block;
			float: left;
			margin: 0;
			//padding-left: 14px;
			//padding-right: 14px;
			//padding-top: 10px;
			//padding-bottom: 8px;
			//height: 41px;

			z-index: 20;

			&:hover > a {

				color: $color-menu-main-hover;
			}

			a {

				color: $color-menu-main-normal;
				text-decoration: none;
				display: block;

				/* &:hover {

					color: $color-menu-main-hover;
					cursor: pointer;
				} */
			}

			ul {
				//
				background: $white;
				visibility: hidden;
				float: left;
				min-width: 200px;
				position: absolute;
				left: 0;
				transform: translate(-30%,0);
				padding-bottom: 10px;

				li {

					z-index: 20;

					padding-top: 0;
					height: auto;

					width: 200px;
					text-align: center;

					line-height: 20px;
    				margin-bottom: 10px;

					a {

						color: $color-topmenu-bright;

						//padding-left: 20px;
					}

					&:hover {

						a {
							color: $color-menu-main-normal;
						}
					}

					&:first-child {
						margin-top: 20px;
					}

					/* a {

						color: $color-menu-main-normal;
						text-decoration: none;
						display: block;

						&:hover {

							color: $color-menu-main-hover;
							cursor: pointer;
						}
					} */

				}

			}

			// ul:hover {

			// 	visibility: visible;
			// }

			&:last-child {

				a {

					padding-right: 0;
				}
			}
		}
	}



	&.mm-menu {

		li {
			float: none;
		}
	}

	/* li:hover a,
	li.current-menu-item a {
		color: $color-menu-main-hover;
	} */

	ul li:hover > ul,
	ul li ul:hover {
	   visibility: visible;
	}
}
}
