// @include breakpoint(small only) {
// 	.cc-revoke.cc-bottom {
// 		bottom: 80px;
// 		right: 3em;
// 		border-top-left-radius: 0.5em;
// 		border-top-right-radius: 0.5em;
// 		border-color: red;
// 	}

// 	@include breakpoint(landscape) {
// 		right: 3em;
// 	}
// }
// @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
@media (hover: none) and (pointer: coarse) {
	/* your CSS to target only landscape mobile users */
	.cc-revoke.cc-bottom {
		bottom: 0;
		right: 3em;
		left: auto;
		border-top-left-radius: 0.5em;
		border-top-right-radius: 0.5em;
		border-color: red;
	}
}
